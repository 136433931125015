import React, { useContext, useState } from 'react';
import { Col, Input, InputGroup, Row } from 'reactstrap';
import { Picker } from 'emoji-mart';
import { Btn, Image } from '../../AbstractElements';
import ChatAppContext from '../../_helper/Chat';

import './Chat.css';

const SendChat = () => {
  const [messageInput, setMessageInput] = useState('');

  const {
    chatss,
    selectedUserr,
    currentUserr,
    sendMessageAsyn,
    replyByUserAsyn,
  } = useContext(ChatAppContext);

  const addEmoji = (emoji) => {
    const text = `${messageInput}${emoji.native}`;
    setMessageInput(text);
  };
  const handleMessageChange = (message) => {
    setMessageInput(message);
  };

  const handleMessagePress = (value) => {
    var container = document.querySelector('.chat-history');
    setTimeout(function () {
      container.scrollBy({ top: 200, behavior: 'smooth' });
    }, 310);

    let currentUserId = currentUserr.id;
    let selectedUserId = selectedUserr.id;
    let selectedUserName = selectedUserr.name;

    sendMessageAsyn(currentUserId, selectedUserId, value, chatss);
    // setTimeout(() => {
      const replyMessage =
        'Hey This is ' +
        selectedUserName +
        ', Sorry I busy right now, I will text you later';
      
      selectedUserr.online = true;
      replyByUserAsyn(currentUserId, selectedUserId, replyMessage, chatss);
    // }, 1000);
  };

  const ChatPill = ({ value, onClick }) => (
    <button className="d-flex align-items-center pill-chat py-2 px-3 text-start" onClick={onClick}>
      <span>{value}</span>
    </button>
  )

  return (
    <div className="chat-message clearfix">
      <Row>
        <Col xl="12" className="d-flex pill-chat-container justify-content-center">
          <ChatPill 
            value={'Surprise me!'}
            onClick={() => {
              handleMessagePress('Surprise me!')
            }}
          />
          
          <ChatPill 
            value={'Hi there!'}
            onClick={() => {
              handleMessagePress('Hi there!')
            }}
          />
        </Col>
        
        {/* <Col xl="12" className="d-flex mt-4">
          <InputGroup className="text-box">
            <Input
              type="text"
              className="form-control input-txt-bx"
              placeholder="Type a message......"
              value={messageInput}
              onKeyPress={(e) => handleMessagePress(e)}
              onChange={(e) =>
                handleMessageChange(e.target.value)
              }
            />
            <Btn
              attrBtn={{
                color: 'primary'
                , onClick: () => handleMessagePress('send')
              }}>
              Send
            </Btn>
          </InputGroup>
        </Col> */}
      </Row>
    </div>
  );
};

export default SendChat;