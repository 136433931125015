import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

console.log('REACT_APP_API_BASE_URL', REACT_APP_API_BASE_URL)
const axiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL, // The base URL for your API
  timeout: 1200000, // Timeout after 10 seconds
});

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyN2IzMjQ1LTA0OTMtNGNlOS05MmNhLWFkMWQ4ZDZkZTY2MyIsImlhdCI6MTczNzMwMzgwOCwiZXhwIjoxNzM5ODk1ODA4fQ.GoyNrjFF8cH6yNapAbT38nXV_QhmNEUlz2EPtx3-3jk';

// You can add request or response interceptors here if needed
axiosInstance.interceptors.request.use(
  (config) => {
    // Add authorization token or other headers here
    // const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;