import React from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'; // Import styles for pagination
import { Worker } from '@react-pdf-viewer/core'; // For PDF rendering
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'; // Import the page navigation plugin
import { Row, Col, Card, CardHeader, CardBody, Button, CardFooter } from "reactstrap";

const PDFViewer = ({ colSize=10, fileName, url, onScan }) => {
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin(); // Create an instance of the page navigation plugin

  return (
    <Col sm={colSize}>
      <Card>
        {fileName && (
          <CardHeader>
            <h4>{fileName}</h4>
          </CardHeader>
        )}

        <CardBody style={{ height: 'calc(85vh - 20px)' }}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
            <Viewer
              fileUrl={url} // Replace with the path to your PDF
              plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
              defaultScale={SpecialZoomLevel.PageFit}
            />
          </Worker>
        </CardBody>

        {onScan && (
          <CardFooter>
            <Col>
              {pageNavigationPluginInstance.CurrentPageInput && (
                <pageNavigationPluginInstance.CurrentPageInput /> // Render the current page input
              )}
            </Col>
            <Button onClick={onScan}>Scan Sekarang</Button>
          </CardFooter>
        )}
      </Card>
    </Col>
  );
};

export default PDFViewer;
