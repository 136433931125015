import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import CustomizerContext from "../../_helper/Customizer";

import API from '../../api/services'

import UploadStnk from './UploadStnk';
import Loading from './Loading';
import EditStnk from './EditStnk';
import PreviewId from "./PreviewId";

import './Stnk.css'

const MOCKUP_DATA_STNK = [
  {
      "alamat": "CLUSTER BELLE FLEUR BLOK L 25 NO 5 RT 003 RW 004 KEL. CIAKAR KEC. PANONGAN KAB. TANGERANG",
      "kode_lokasi": "0302",
      "nama_pemilik": "LINDA",
      "berlaku_sampai": "18 April 2028",
      "vehicle_details": {
          "merk": "TOYOTA",
          "tipe": "AGYA 1.2 G A/T",
          "jenis": "MB. PENUMPANG",
          "model": "MINIBUS",
          "warna": "PUTIH",
          "nomor_bpkb": "T05761410H1",
          "warna_tnkb": "PUTIH",
          "bahan_bakar": "BENSIN",
          "nomor_mesin": "3NRH248136",
          "isi_silinder": "1197",
          "nomor_rangka": "MHKA4GB65JJ011837",
          "tahun_pembuatan": "2018",
          "tahun_registrasi": "2023",
          "no_urut_pendaftaran": "698/015-302/FL/17042023"
      },
      "nomor_registrasi": "A 1713 XMA",
      "nik_tdp_nib_kitas_kitap": "1671114409810004",
      "stnkType": "atas",
      "id": "549eb61b-1145-4599-b041-1105748f8160",
      "vehicleId": "f92ab65f-5afb-40f6-9e72-6093aceed4d0",
      "filePath": "1736821424800-78q4i.jpeg",
      "fileUrl": "https://api.notapos.co/public/stnk/1736821424800-78q4i.jpeg"
  },
  {
      "nik": null,
      "alamat": "Cluster Belle Fleur Bln. L255/RT.03/04 Cisauk Pagedangan Kab. Tangerang",
      "no_urut": "00626040",
      "no_kohir": null,
      "kode_lokasi": null,
      "tax_details": {
          "pkb_pokok": "2292000",
          "pkb_jumlah": "2292000",
          "pkb_sanksi": "0",
          "bbnkb_pokok": null,
          "bbnkb_jumlah": null,
          "bbnkb_sanksi": null,
          "swdkljj_pokok": "143000",
          "swdkljj_jumlah": "143000",
          "swdkljj_sanksi": "0",
          "biaya_adm_tnkb_pokok": null,
          "biaya_adm_tnkb_jumlah": null,
          "biaya_adm_tnkb_sanksi": null,
          "biaya_adm_penerbitan_stnk_jumlah": null,
          "biaya_adm_penerbitan_stnk_pokok": null,
          "biaya_adm_penerbitan_stnk_sanksi": null
      },
      "nama_pemilik": "Linda",
      "nomor_polisi_sekarang": "BG17520D",
      "berlaku_sampai": "18-04-2025",
      "vehicle_details": {
          "merk": "Toyota",
          "type": "Agya 1.2 G A/T (B101RA-GQ5GJ)",
          "ident": null,
          "jenis": "Minibus",
          "model": null,
          "warna": "Putih",
          "nomor_bpkb": "T0575141011",
          "warna_tnkb": "Putih",
          "bahan_bakar": "Bensin",
          "nomor_mesin": "3NRH243136",
          "isi_silinder": "1197",
          "nomor_rangka": "MHKA4GB5JJJ011637",
          "tahun_pembuatan": "2018",
          "tahun_registrasi": "2018",
          "tipe": "Agya 1.2 G A/T (B101RA-GQ5GJ)"
      },
      "stnkType": "bawah",
      "id": "549eb61b-1145-4599-b041-1105748f8160",
      "vehicleId": "77c6d469-dfb4-4380-9d46-4c6cd09c5616",
      "filePath": "1736821430106-h4nq0a.jpeg",
      "fileUrl": "https://api.notapos.co/public/stnk/1736821430106-h4nq0a.jpeg",
      "taxId": "dc20779a-da9f-46ae-abe7-224d9b17e765"
  }
];

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const Stnk = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);
  const [stnkData, setStnkData] = useState([]);

  const [isProcessingOcr, setIsProcessingOcr] = useState(false);
  const [isFinishedOcr, setIsFinishedOcr] = useState(false);

  const [isUpdatingStnk, setIsUpdatingStnk] = useState(false);
  const [isFinishGenerate, setIsFinishGenerate] = useState(false);

  const [isSavingParagraph, setISavingParagraph] = useState(false);

  useEffect(() => {
    setHeaderMenu('Scan STNK')
  }, [])

  const handleUploadStnk = async (files) => {
    let payload = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const filePhoto = file.photo

      if (file && filePhoto.file.type.startsWith('image/')) {
        const data = {
          type: file.type,
          photo: await getBase64(filePhoto.file),
        }
        payload = [...payload, data]
      }
    }

    setIsProcessingOcr(true);

    try {
      const response = await API.akta.uploadStnk(payload);
      console.log('response', response)

      if (response) {
        const { extractedData } = response;
        const id = extractedData[0].id;
        const stnkName = `${extractedData[0]?.vehicle_details?.merk} ${extractedData[0]?.vehicle_details?.tipe}`;

        const historyData = {
          name: `STNK ${stnkName}`,
          url: `list/stnk/${id}`,
          type: 'stnk',
        }
        API.history.insertHistory(historyData).then(x => console.log('success saved history'))

        setStnkData(extractedData);
        setIsFinishedOcr(true);
        setIsProcessingOcr(false);
      } else {
        alert(JSON.stringify(response));
        setIsProcessingOcr(false);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(JSON.stringify(error));
      setIsProcessingOcr(false);
    }
  };

  const saveDataKtp = async(data) => {
    setIsFinishedOcr(false);
    setIsUpdatingStnk(true);

    console.log('saveDataKtp data', data)

    API.akta.updateStnk(data).then(() => {
      setIsUpdatingStnk(false);
      setStnkData([])
    })
    .catch(err => {
      alert('Error save data KTP: ' + JSON.stringify(err))
      
      setIsFinishedOcr(true);
      setIsUpdatingStnk(false);
    })
  }

  return (
    <Fragment>
      <Container
        fluid={true}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        {stnkData.length === 0 && !isProcessingOcr && (
          <UploadStnk
            title="Upload & Scan STNK"
            subtitle="Pilih dan upload file STNK yang ingin kamu scan"
            onSubmit={handleUploadStnk}
            type="stnk"
          />
        )}

        {isProcessingOcr && (
          <Loading
            title="Processing Magic"
            body="AI OCR is decoding your data in no time."
          />
        )}

        {stnkData.length > 0 && isFinishedOcr && (
          <EditStnk
            data={stnkData}
            onDone={saveDataKtp}
          />
        )}

        {isUpdatingStnk && (
          <Loading
            title="Updating in Progress!"
            body="AI is safeguarding your data in our secure database."
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Stnk;

// Inline styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '1rem',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    fontSize: '18px',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  subtext: {
    fontSize: '14px',
    color: '#888',
    marginBottom: '20px',
  },
  filesPreviewContainer: {
    display: 'flex',
    gap: '15px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  fileCard: {
    width: '15rem',
    height: '10rem',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#000',
    padding: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#FFF'
  },
  fileSize: {
    color: '#888',
    fontSize: '10px',
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "#FFFFFF",
    cursor: "pointer",
    color: "rgb(255, 95, 95)",
    zIndex: 1,
    padding: "0",
    margin: "0",
    border: "0",
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
  },
  dropzone: {
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#f2f2f5',
    cursor: 'pointer',
    color: '#666',
    height: '15rem',
    textAlign: 'center',
    alignContent: 'center',
  },
  browse: {
    textDecoration: 'underline',
    color: '#007bff',
    cursor: 'pointer',
  },
  orText: {
    marginTop: '10px',
    fontSize: '12px',
    color: '#555',
  },
  scanButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};