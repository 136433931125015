import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  CardHeader,
} from "reactstrap";
import { H3 } from '../../AbstractElements'

import { FaTrashAlt, FaEdit, FaShareAlt, FaCopy } from 'react-icons/fa';

// Helper function to capitalize the first letter
const capitalize = (str) => {
  if (!str) return '';
  return str.toLowerCase().split(' ').map(function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
};

const generateGelar = (data) => (
  data.trim().toUpperCase() === "PEREMPUAN"
  ? 'NYONYA'
  : (
    data.trim().toUpperCase() === "LAKI-LAKI"
    ? 'TUAN'
    : ''
  )
);

const generateAktaFormat = (placeholders) => (
  `${generateGelar(placeholders?.jenisKelamin)} ${placeholders?.nama}, lahir di ${placeholders?.tempatLahir}, pada tanggal ${placeholders?.tglLahir} (${placeholders?.tglLahir_terbilang}), Warga Negara Indonesia, ${placeholders?.pekerjaan}, bertempat tinggal di ${placeholders?.kotaKabupaten}, ${placeholders?.alamat}, Rukun Tetangga ${placeholders?.rt}, Rukun Warga ${placeholders?.rw}, Kelurahan ${placeholders?.kelDesa}, Kecamatan ${placeholders?.kecamatan}, ${placeholders?.provinsi}, pemegang Kartu Tanda Penduduk dengan Nomor Induk Kependudukan: ${placeholders?.nik}`
);

const ActionButton = ({
  onClick,
  style={},
  additionalClass={},
  outline = false,
  children
}) => {
  let className = `d-flex py-2 px-3 align-items-center ${additionalClass}`
  
  return (
    <Button
      className={className}
      style={{
        backgroundColor: '#E6E6E6',
        ...style,
      }}
      outline={outline}
      onClick={onClick}
      color="light"
    >
      {children}
    </Button>
  )
}

const PreviewId = ({ data, onSave }) => {
  const [copiedText, setCopiedText] = useState([]);

  useEffect(() => {
    const newData = data.map((el, index) => ({ index, isCopied: false }))
    setCopiedText(newData)
  }, [])

  const cards = data.map((el, index) => {
    const tempatLahir = capitalize(el?.tempatLahir?.toLowerCase());
    const pekerjaan = capitalize(el?.pekerjaan?.toLowerCase());
    const kotaKabupaten = capitalize(el?.kotaKabupaten?.toLowerCase());
    const alamat = capitalize(el?.alamat?.toLowerCase());
    const kelDesa = capitalize(el?.kelDesa?.toLowerCase());
    const kecamatan = capitalize(el?.kecamatan?.toLowerCase());
    const provinsi = capitalize(el?.provinsi?.toLowerCase());

    const newData = {
      ...el,
      tempatLahir,
      pekerjaan,
      kotaKabupaten,
      alamat,
      kelDesa,
      kecamatan,
      provinsi,
    };

    return {
      title: `ID ${index + 1}`,
      body: generateAktaFormat(newData),
      ...newData
    };
  });

  const handleDelete = (index) => {
    console.log("Delete card:", index);
  };

  const handleEdit = (index) => {
    console.log("Edit card:", index);
  };

  const handleShare = (index) => {
    console.log("Share card:", index);
  };

  const handleCopy = (index, text) => {
    navigator.clipboard.writeText(text);
    
    const newCopiedText = copiedText.map(el => el.index === index ? { ...el, isCopied: true } : el)
    setCopiedText(newCopiedText);
  };

  const handleSaveParagraph = () => {
    let tempData = [];
    for (let index = 0; index < cards.length; index++) {
      const card = cards[index];
      tempData = [
        ...tempData,
        {
          'idcardId': card.id,
          'paragraph': card.body,
        }
      ]
    }

    console.log('tempData', tempData)
    onSave(tempData)
  }

  return (
    <Fragment>
      <Row>
        <Col sm={8}>
          <Card>
            <CardHeader>
              <H3>Hasil Uraian ID</H3>
            </CardHeader>

            <CardBody>
              {cards.map((card, index) => (
                <Card key={index} style={{ boxShadow: 'none', border: '1px solid #ecf3fa'}}>
                  <CardBody>
                    <p className='card-title'>{card.title} • {card.nama}</p>
                    <p>{generateGelar(card?.jenisKelamin)} <strong>{card?.nama}</strong>, lahir di {card?.tempatLahir}, pada tanggal {card?.tglLahir} ({card?.tglLahir_terbilang}), Warga Negara Indonesia, {card?.pekerjaan}, bertempat tinggal di {card?.kotaKabupaten}, {card?.alamat}, Rukun Tetangga {card?.rt}, Rukun Warga {card?.rw}, Kelurahan {card?.kelDesa}, Kecamatan {card?.kecamatan}, {card?.provinsi}, pemegang Kartu Tanda Penduduk dengan Nomor Induk Kependudukan: {card?.nik}</p>
                  
                    <div className="d-flex justify-content-between">
                      {/* <ActionButton
                        onClick={() => handleDelete(index)}
                        outline
                      >
                        <FaTrashAlt size={16} color="#d03838"/>
                      </ActionButton> */}

                      <div className="d-flex flex-fill justify-content-end">
                        {/* <ActionButton
                          additionalClass="me-3"
                          outline
                          onClick={() => handleEdit(index)}
                        >
                          <FaEdit size={16} color="#373737"/>
                        </ActionButton>

                        <ActionButton
                          additionalClass="me-3"
                          outline
                          onClick={() => handleShare(index)}
                        >
                          <FaShareAlt size={16} color="#373737"/>
                        </ActionButton> */}

                        <ActionButton
                          outline
                          onClick={() => handleCopy(index, card.body)}
                        >
                          {
                            copiedText.filter(el => el.index === index)[0]?.isCopied
                          ? (
                            <span className="ms-1" style={{ color: '#000' }}>
                            Copied!
                            </span>
                          )
                          : (
                            <>
                              <FaCopy size={16} color="#000"/>
                              <span className="ms-1" style={{ color: '#000' }}>
                                Copy
                              </span>
                            </>
                          )
                        }
                        </ActionButton>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </CardBody>

            <CardFooter className="text-end">
              <Button onClick={handleSaveParagraph}>
                Simpan ke Database
              </Button>
            </CardFooter>
          </Card>
        </Col>

        <Col sm={4}>
          <Card>
            <CardHeader>
              <H3>Preview ID</H3>
            </CardHeader>

            <CardBody>
              {cards.map((card, index) => (
                <div className="mb-3">
                  <p className='card-title'>{card.title} • {card.nama}</p>
                  <img src={card.ktpPhoto} style={{ width: '100%', height: '100%' }}/>
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PreviewId;