import React, { Fragment, useEffect, useContext } from 'react';
import Chatting from './Chatting';
import CustomizerContext from "../../_helper/Customizer";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const ChatAppContain = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);

  useEffect(() => {
    setHeaderMenu('Create Akta Insight')
  }, [])

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col className='call-chat-body mt-4'>
            <Card>
              <CardBody className='p-0'>
                <Chatting />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ChatAppContain;
