import React, { useState } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { Close as CloseIcon } from '@mui/icons-material';
import { useDropzone } from "react-dropzone";

import { H5 } from "../../AbstractElements";

const FileInfo = ({ file }) => (
  <div style={styles.fileInfo}>
    <p className="m-0" style={styles.fileName}>{file.file.name}</p>
    <p className="m-0" style={styles.fileSize}>
      {
        file.file.size === 'Database ID'
        ? 'Database ID'
        : `${(file.file.size / 1024).toFixed(1)} KB`
      }
    </p>
  </div>
);

const UploadedItem = ({ file, onRemove }) => (
  <div className="d-flex mb-3" style={styles.fileCard}>
    <Row className="justify-content-center align-items-center p-3" style={{ flex: 1 }}>
      <Col sm={3}>
        <img src={file.preview} style={{ width: '100%', height: '100%', maxHeight: '6rem', objectFit: 'contain' }}/>
      </Col>
      
      <Col sm={7}>
        <FileInfo file={file} />
      </Col>

      <Col sm={2} className="d-flex justify-content-end">
        <button
          style={styles.removeButton}
          onClick={(e) => onRemove(e)}
        >
          <CloseIcon style={{ flex: 1, fontSize: 14, color: '#FFFFFF' }} />
        </button>
      </Col>
    </Row>
  </div>
)

const Upload = ({
  title,
  subtitle,
  onSubmit,
}) => {
  const [fileFront, setFileFront] = useState(null);
  const [fileBack, setFileBack] = useState(null);

  // Handle drop event
  const onDropFileFront = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => {
      const fileName = file.name;
      const fileSize = file.size;
      const fileLastModified = file.lastModified;

      return {
        file,
        preview: URL.createObjectURL(file),
      };
    }).filter(Boolean);

    setFileFront(...newFiles);
  };

  // Handle drop event
  const onDropFileBack = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => {
      const fileName = file.name;
      const fileSize = file.size;
      const fileLastModified = file.lastModified;

      return {
        file,
        preview: URL.createObjectURL(file),
      };
    }).filter(Boolean);

    setFileBack(...newFiles);
  };

  const removeFileFront = (e) => {
    e.stopPropagation();
    setFileFront(null);
  };
  
  const removeFileBack = (e) => {
    e.stopPropagation();
    setFileBack(null);
  };

  // Dropzone setup
  const {
    getRootProps: getRootFileFrontProps,
    getInputProps: getInputFileFrontProps,
    fileRejections: fileFrontRejections
  } = useDropzone({
    onDrop: onDropFileFront,
    maxFiles: 1, // Set the maximum number of files to 3
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
  });

  const dropzoneRootFileFrontProps = (
    getRootFileFrontProps({ style: {...styles.dropzone, minHeight: '14rem'} })
  )

  // Dropzone setup
  const {
    getRootProps: getRootFileBackProps,
    getInputProps: getInputFileBackProps,
    fileRejections: fileBackRejections
  } = useDropzone({
    onDrop: onDropFileBack,
    maxFiles: 1, // Set the maximum number of files to 3
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
  });

  const dropzoneRootFileBackProps = (
    getRootFileBackProps({ style: {...styles.dropzone, minHeight: '14rem'} })
  )

  return (
    <Col sm={10}>
      <Card>
        <CardHeader>
          <H5>{title}</H5>
          <p className="m-0">{subtitle}</p>
        </CardHeader>

        <CardBody>
          {fileFront && (
            <UploadedItem
              file={fileFront}
              onRemove={(e) => removeFileFront(e)}
            />
          )}
          
          {!fileFront && (
            <div {...dropzoneRootFileFrontProps}>
              <input {...getInputFileFrontProps()} />
              <i className="fa fa-cloud-upload" style={{fontSize: '4rem'}}></i>
              <p>Drop files here or click to upload STNK Atas</p>
              {fileFrontRejections.length > 0 && (
                <p style={{ color: 'red' }}>You can only upload 1 file.</p>
              )}
            </div>
          )}

          <br />

          {fileBack && (
            <UploadedItem
              file={fileBack}
              onRemove={(e) => removeFileBack(e)}
            />
          )}
          
          {!fileBack && (
            <div {...dropzoneRootFileBackProps}>
              <input {...getInputFileBackProps()} />
              <i className="fa fa-cloud-upload" style={{fontSize: '4rem'}}></i>
              <p>Drop files here or click to upload STNK Bawah</p>
              {fileBackRejections.length > 0 && (
                <p style={{ color: 'red' }}>You can only upload 1 file.</p>
              )}
            </div>
          )}
        </CardBody>

        <CardFooter>
          <div className="d-flex justify-content-end">
            <button
              style={styles.scanButton}
              onClick={() => {
                if (!fileFront || !fileBack) {

                } else {
                  onSubmit([
                    {
                      type: 'atas',
                      photo: fileFront,
                    },
                    {
                      type: 'bawah',
                      photo: fileBack,
                    },
                  ])
                }
              }}
            >Scan Sekarang</button>
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default Upload;

// Inline styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '1rem',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  fileCard: {
    height: '8rem',
    borderRadius: '10px',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#d0d0d0',
    padding: 0,
  },
  fileInfo: {
    padding: '5px',
    fontSize: '12px',
    textAlign: 'left',
  },
  fileName: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#000',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileSize: {
    color: '#888',
    fontSize: '10px',
  },
  removeButton: {
    background: "#4a4a4a",
    cursor: "pointer",
    zIndex: 1,
    padding: "0",
    margin: "0",
    border: "0",
    borderRadius: "50%",
    width: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
  },
  dropzone: {
    padding: '20px',
    borderRadius: '10px',
    cursor: 'pointer',
    color: '#666',
    textAlign: 'center',
    alignContent: 'center',
    border: '1px dashed',
  },
  scanButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#555555',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};