import axiosInstance from "../utils/axiosInstance";

const getListDocument = async () => {
  try {
    const response = await axiosInstance.get("/document/list");

    return response.data;
  } catch (error) {
    console.error("Get list document failed:", error);
    throw error;
  }
};

const getDetailDocument = async (id) => {
  try {
    const response = await axiosInstance.get(`/document/detail/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("getDetailDocument failed:", error);
    throw error;
  }
};

const uploadDocuments = async (formData) => {
  try {
    const response = await axiosInstance.post("/document/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const getListStnk = async () => {
  try {
    const response = await axiosInstance.get("/document/list/stnk");

    return response.data;
  } catch (error) {
    console.error("Get list document failed:", error);
    throw error;
  }
};

const getDetailStnk = async (id) => {
  try {
    const response = await axiosInstance.get(`/document/detail/stnk/${id}`);
    return response.data.data;
  } catch (error) {
    console.error("getDetailDocument failed:", error);
    throw error;
  }
};

const uploadStnk = async (stnk) => {
  try {
    const response = await axiosInstance.post(
      "/document/extract-stnk",
      { stnk },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const updateStnk = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/document/update/stnk",
      { data },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

const exportDocumentToWord = async (markdown) => {
  try {
    const response = await axiosInstance.post(
      "/document/process-markdown",
      { markdown },
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const extractedFileName = "Generated Akta.docx";

    // Create a URL for the file
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const fileUrl = URL.createObjectURL(file);

    // Automatically trigger file download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = extractedFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export {
  getListDocument,
  getDetailDocument,
  uploadDocuments,
  getListStnk,
  getDetailStnk,
  uploadStnk,
  updateStnk,
  exportDocumentToWord,
};
