import React, { Fragment, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Media,
} from "reactstrap";
import { H4, Btn } from "../../AbstractElements";

import InputData from './InputData'

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

const Button = ({ text, color="primary", className="m-r-15", style={}, onClick }) => (
  <Btn
    attrBtn={{ color, className, type: "submit", style, onClick }}
  >
    {text}
  </Btn>
)


const PreviewImage = ({ data, image, index, onClick }) => (
  <div
    className="p-3 text-center"
    style={{
      position: 'relative',
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '21rem',
      borderRadius: '15px',
    }}
  > 
    <div className="d-flex gap-2 position-absolute" style={{ bottom: '1rem' }}>
      {
        data.map((el, i) => (
          <Button
            key={i}
            className="px-2 py-1"
            color={index === i ? '#FFF' : 'transparent'}
            style={{
              border: '1px solid #808080',
              borderRadius: '8px',
              backgroundColor: '#808080'
            }}
            onClick={() => onClick(i)}
            text={<span style={{ color: index === i ? '#FFF' : '#3f3f3f' }}>ID {i + 1}</span>}
          />
        ))
      }
    </div>
  </div>
)

const EditId = ({ data, onRetake, onDone }) => {
  const fileInputRef = useRef(null);
  const [selfiePhoto, setSelfiePhoto] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState(data);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.startsWith('image/')) {
      const base64 = await getBase64(selectedFile);
      updateFormData(base64, 'selfiePhoto')

      event.preventDefault();

      const reader = new FileReader();

      // Once the file is read, set the image source to the result
      reader.onload = (e) => {
        setSelfiePhoto(e.target.result); // Set the state with the image data URL
      };

      // Check if the file is a valid file object (Blob)
      if (selectedFile instanceof Blob) {
        reader.readAsDataURL(selectedFile); // Only call this if it's a Blob/File object
      } else {
        console.error('Invalid file type');
      }
    } else {
      alert('Only image allowed')
    }
  };

  const changeFormData = (index) => {
    setCurrentIndex(index)
  }

  const updateFormData = (value, data) => {
    const tempFormData = [...formData];
    tempFormData[currentIndex] = {
      ...tempFormData[currentIndex],
      [data]: value.toUpperCase(),
    };
    setFormData(tempFormData);
  }

  const validateFormData = (data) => {
    const requiredFields = [
      { field: "nik", message: "NIK is empty" },
      { field: "nama", message: "Nama Lengkap is empty" },
      { field: "tempatLahir", message: "Tempat Lahir is empty" },
      { field: "tglLahir", message: "Tanggal Lahir is empty" },
      { field: "jenisKelamin", message: "Jenis Kelamin is empty" },
      { field: "alamat", message: "Alamat is empty" },
      { field: "rtRw", message: "RT RW is empty" },
      { field: "kelDesa", message: "Kel/Desa is empty" },
      { field: "kecamatan", message: "Kecamatan is empty" },
      { field: "kotaKabupaten", message: "Kota/Kabupaten is empty" },
      { field: "provinsi", message: "Provinsi is empty" },
      { field: "agama", message: "Agama is empty" },
      { field: "status", message: "Status Perkawinan is empty" },
      { field: "pekerjaan", message: "Pekerjaan is empty" },
      { field: "tempatDibuat", message: "Tempat Dibuat is empty" },
    ];
  
    for (const { field, message } of requiredFields) {
      if (!data?.[field]) {
        alert(message);
        return false;
      }
    }
  
    return true; // All validations passed
  };

  const validateSubmit = () => {
    if (!validateFormData(formData[currentIndex], currentIndex)) {
      // Stop further execution if validation fails
      return false;
    }

    onDone(formData);
    return true;
  }

  const validateInputNik = (value) => value && value.length === 16;
  const validateInputBirthDate = (value) => value && /^\d{2}-\d{2}-\d{4}$/.test(value);

  return (
    <Row className="justify-content-center mt-3" style={{ width: '100%' }}>
      <Col>
        <Card>
          <Form className="form theme-form" onSubmit={handleSubmit}>
            <CardBody>
              <H4 attrH4={{ className: "mt-3" }}>ID {currentIndex + 1} - {formData[currentIndex]?.nama}</H4>

              <InputData
                label="NIK"
                value={formData[currentIndex]?.nik ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'nik')}
                maxLength={16}
                validationRule={validateInputNik}
                errorMessage="Panjang NIK harus 16 karakter"
              />

              <InputData
                label="Nama Lengkap"
                value={formData[currentIndex]?.nama ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'nama')}
              />

              <InputData
                label="Tempat Lahir"
                value={formData[currentIndex]?.tempatLahir ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'tempatLahir')}
              />

              <InputData
                label="Tanggal Lahir (DD-MM-YYYY)"
                value={formData[currentIndex]?.tglLahir ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'tglLahir')}
                validationRule={validateInputBirthDate}
                errorMessage="Tanggal Lahir harus DD-MM-YYYY"
              />

              <InputData
                label="Jenis Kelamin"
                value={formData[currentIndex]?.jenisKelamin ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'jenisKelamin')}
              />

              <InputData
                label="Alamat"
                value={formData[currentIndex]?.alamat ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'alamat')}
              />

              <InputData
                label="RT/RW"
                value={formData[currentIndex]?.rtRw ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'rtRw')}
              />

              <InputData
                label="Desa/Kelurahan"
                value={formData[currentIndex]?.kelDesa ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'kelDesa')}
              />

              <InputData
                label="Kecamatan"
                value={formData[currentIndex]?.kecamatan ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'kecamatan')}
              />

              <InputData
                label="Kota/Kabupaten"
                value={formData[currentIndex]?.kotaKabupaten ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'kotaKabupaten')}
              />

              <InputData
                label="Provinsi"
                value={formData[currentIndex]?.provinsi ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'provinsi')}
              />

              <InputData
                label="Agama"
                value={formData[currentIndex]?.agama ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'agama')}
              />

              <InputData
                label="Status Perkawninan"
                value={formData[currentIndex]?.status ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'status')}
              />

              <InputData
                label="Pekerjaan"
                value={formData[currentIndex]?.pekerjaan ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'pekerjaan')}
              />

              <InputData
                label="Tempat Dibuat"
                value={formData[currentIndex]?.tempatDibuat ?? ''}
                onChange={(event) => updateFormData(event.target.value, 'tempatDibuat')}
              />

              {/* <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <label className="form-label">Selfie Photo</label>
                    {selfiePhoto && (
                      <>
                        <Media
                          src={selfiePhoto}
                          className="p-3"
                          style={{
                            width: '100%', height: '200px',
                            border: '1px solid #efefef',
                            borderRadius: '8px',
                          }}
                          alt=""
                          onClick={() => fileInputRef.current.click()}
                        />
                      </>
                    )}

                    {!selfiePhoto && (
                      <Button
                        color="primary"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => fileInputRef.current.click()}
                      >
                        Upload Selfie photo
                      </Button>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              

              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the input element
                onChange={handleFileChange}
              /> */}
            </CardBody>

            <CardFooter className="text-end">
              {
                currentIndex === (data.length - 1)
                ? (
                  <Button
                    onClick={validateSubmit}
                    text="Buat Uraian ID"
                  />
                )
                : (
                  <Button
                    onClick={() => {
                      if (validateFormData(formData[currentIndex], currentIndex)) {
                        changeFormData(currentIndex + 1)
                      }
                    }}
                    text="Selanjutnya"
                  />
                )
              }      
            </CardFooter>
          </Form>
        </Card>
      </Col>

      <Col>
        <Card>
          <PreviewImage
            index={currentIndex}
            data={formData}
            image={formData[currentIndex].ktpPhoto}
            onClick={(index) => {
              if (validateFormData(formData[currentIndex], currentIndex)) {
                changeFormData(index)
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default EditId;
