import React, { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from "reactstrap";

const Dropdown = ({
  label,
  data,
  disabled,
  onChange,
}) => (
  <Row>
    <Col>
      <FormGroup>
        <Label>{label}</Label>
        <Input
          type="select"
          name="select"
          className="form-control digits"
          placeholder={label}
          defaultValue={null}
          onChange={onChange}
          disabled={disabled}
        >
          {data}
        </Input>
      </FormGroup>
    </Col>
  </Row>
)

const TextInput = ({
  label,
  value,
  onChange,
  disabled,
  maxLength=undefined,
  validationRule=(value) => value && value.length > 0,
  errorMessage="Harus diisi",
}) => {
  const [error, setError] = useState(false);

  // Function to validate input
  const validate = (value) => {
    console.log('value', value)
    console.log('validationRule', validationRule)
    console.log('validationRule(value)', validationRule(value))
    if (!value || (validationRule && !validationRule(value))) {
      setError(true);
    } else {
      setError(false);
    }
  };

  // Handle onBlur to validate input
  const handleBlur = () => {
    validate(value);
  };

  return (
    <Row className="mt-3">
      <Col>
        <FormGroup className="row">
          <Label className="col-sm-4 col-form-label">{label}</Label>
          <Col sm="8">
            <Input
              style={{ borderColor: error ? '#dc3545' : '#efefef'}}
              className={`form-control`}
              placeholder={label}
              value={value}
              onChange={(e) => {
                onChange(e);
                validate(e.target.value);
              }}
              onBlur={handleBlur}
              disabled={disabled}
              maxLength={maxLength}
            />
            {error && (
              <FormFeedback style={{ display: 'block', textAlign: 'right' }}>
                {errorMessage || 'This field is required'}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>

        {/* <FormGroup>
          <Label>{label}</Label>
          <Input
            className="form-control"
            placeholder={label}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </FormGroup> */}
      </Col>
    </Row>
  );
};

const InputData = ({
  label,
  value,
  data,
  onChange,
  placeholder='',
  disabled=false,
  type="text",
  maxLength=undefined,
  validationRule=(value) => value && value.length > 0,
  errorMessage="Harus diisi",
}) => {
  if (type === "dropdown") {
    let newOptions = data.map((el, index) => (
      <option key={index} value={el.value}>{el.label}</option>
    ));

    if (placeholder) {
      const defaultOption = <option key={100} disabled value="" selected>{placeholder}</option>

      newOptions = [
        defaultOption,
        ...newOptions
      ]
    }

    return (
      <Dropdown
        label={label}
        data={newOptions}
        disabled={disabled}
        onChange={onChange}
      />
    )
  }

  return (
    <TextInput
      label={label}
      value={value}
      disabled={disabled}
      onChange={onChange}
      maxLength={maxLength}
      validationRule={validationRule}
      errorMessage={errorMessage}
    />
  )
};

export default InputData;
