import React, { Fragment, useContext } from 'react';
import { Image, UL, LI } from '../../AbstractElements';
import ChatAppContext from '../../_helper/Chat';
import { AlignJustify, Headphones, Paperclip, Search, Video } from 'react-feather';
import { useLocation } from 'react-router-dom';

const ChatHeader = () => {
  const { selectedUserr, setMenuToggle, menuToggle } = useContext(ChatAppContext);
  const location = useLocation();

  const chatMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };
  return (
    <Fragment>
      <div className='chat-header clearfix'>
        <Image
          attrImage={{
            className: 'rounded-circle',
            src: `${require(`../../assets/images/${selectedUserr ? selectedUserr.thumb : 'user/8.jpg'}`)}`,
            alt: '',
          }}
        />
        <div className='media-body'>
          <div className='about'>
            <div className='name'>
              Notapos AI
            </div>
            <div className='status digits'>{location.state3 ? <span className='font-primary f-12'> Typing...</span> : ''}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ChatHeader;
