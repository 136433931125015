import axiosInstance from '../utils/axiosInstance';

const getListHistory = async (type) => {
  try {
    const response = await axiosInstance.get('/history/list', {
      params: { type },
    });

    return response.data;
  } catch (error) {
    console.error('getListHistory failed:', error);
    throw error;
  }
};

const insertHistory = async (data) => {
  try {
    const response = await axiosInstance.post('/history/save', { data }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('insertHistory failed:', error);
    throw error;
  }
};

export { getListHistory, insertHistory };
