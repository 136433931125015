import React, { Fragment, useContext, useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from "react-data-table-component";
import { Breadcrumbs } from "../../AbstractElements";

import CustomizerContext from '../../_helper/Customizer';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import API from '../../api/services'

const tableColumns = [
  {
    name: 'Nama Lengkap',
    selector: row => row.stnkAtas.nama_pemilik,
    sortable: true,
    center: false,
  },
  {
    name: 'Alamat',
    selector: row => row.stnkAtas.alamat,
    sortable: false,
    center: true,
  },
  {
    name: 'Berlaku Sampai',
    selector: row => row.stnkAtas.berlaku_sampai,
    sortable: true,
    center: true,
  },
  {
    name: 'Kode Lokasi',
    selector: row => row.stnkAtas.kode_lokasi,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Registrasi',
    selector: row => row.stnkAtas.nomor_registrasi,
    sortable: true,
    center: true,
  },
  {
    name: 'NIK/TDP/NIB/KITAS/KITAP',
    selector: row => row.stnkAtas.nik_tdp_nib_kitas_kitap,
    sortable: true,
    center: true,
  },
  {
    name: 'Merk Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.merk,
    sortable: true,
    center: true,
  },
  {
    name: 'Tipe Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.tipe,
    sortable: true,
    center: true,
  },
  {
    name: 'Jenis Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.jenis,
    sortable: true,
    center: true,
  },
  {
    name: 'Model Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.model,
    sortable: true,
    center: true,
  },
  {
    name: 'Warna Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.warna,
    sortable: true,
    center: true,
  },
  {
    name: 'No BPKB Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.nomor_bpkb,
    sortable: true,
    center: true,
  },
  {
    name: 'Warna TNKB Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.warna_tnkb,
    sortable: true,
    center: true,
  },
  {
    name: 'Bahan Bakar Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.bahan_bakar,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Mesin Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.nomor_mesin,
    sortable: true,
    center: true,
  },
  {
    name: 'Isi Silinder Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.isi_silinder,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Rangka Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.nomor_rangka,
    sortable: true,
    center: true,
  },
  {
    name: 'Tahun Pembuatan Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.tahun_pembuatan,
    sortable: true,
    center: true,
  },
  {
    name: 'Tahun Registrasi Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.tahun_registrasi,
    sortable: true,
    center: true,
  },
  {
    name: 'No Urut Pendaftaran Kendaraan',
    selector: row => row.stnkAtas.vehicle_details.no_urut_pendaftaran,
    sortable: true,
    center: true,
  },  
  {
    name: 'NIK',
    selector: row => row.stnkBawah.nik,
    sortable: true,
    center: true,
  },
  {
    name: 'No Urut',
    selector: row => row.stnkBawah.no_urut,
    sortable: true,
    center: true,
  },
  {
    name: 'No Kohir',
    selector: row => row.stnkBawah.no_kohir,
    sortable: true,
    center: true,
  },
  {
    name: 'Kode Lokasi',
    selector: row => row.stnkBawah.kode_lokasi,
    sortable: true,
    center: true,
  },
  {
    name: 'Nama Pemilik',
    selector: row => row.stnkBawah.nama_pemilik,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Polisi Sekarang',
    selector: row => row.stnkBawah.nomor_polisi_sekarang,
    sortable: true,
    center: true,
  },
  {
    name: 'Berlaku Sampai',
    selector: row => row.stnkBawah.berlaku_sampai,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak PKB Pokok',
    selector: row => row.stnkBawah.tax_details.pkb_pokok,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak PKB Jumlah',
    selector: row => row.stnkBawah.tax_details.pkb_jumlah,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak PKB Sanksi',
    selector: row => row.stnkBawah.tax_details.pkb_sanksi,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak BBNKB Pokok',
    selector: row => row.stnkBawah.tax_details.bbnkb_pokok,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak BBNKB Jumlah',
    selector: row => row.stnkBawah.tax_details.bbnkb_jumlah,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak BBNKB Sanksi',
    selector: row => row.stnkBawah.tax_details.bbnkb_sanksi,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak SWDKLJJ Pokok',
    selector: row => row.stnkBawah.tax_details.swdkljj_pokok,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak SWDKLJJ Jumlah',
    selector: row => row.stnkBawah.tax_details.swdkljj_jumlah,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak SWDKLJJ Sanksi',
    selector: row => row.stnkBawah.tax_details.swdkljj_sanksi,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm TNKB Pokok',
    selector: row => row.stnkBawah.tax_details.biaya_adm_tnkb_pokok,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm TNKB Jumlah',
    selector: row => row.stnkBawah.tax_details.biaya_adm_tnkb_jumlah,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm TNKB Sanksi',
    selector: row => row.stnkBawah.tax_details.biaya_adm_tnkb_sanksi,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm Penerbitan Pokok',
    selector: row => row.stnkBawah.tax_details.biaya_adm_penerbitan_pokok,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm Penerbitan Jumlah',
    selector: row => row.stnkBawah.tax_details.biaya_adm_penerbitan_jumlah,
    sortable: true,
    center: true,
  },
  {
    name: 'Pajak Biaya Adm Penerbitan Sanksi',
    selector: row => row.stnkBawah.tax_biaya_adm_penerbitan_sanksi,
    sortable: true,
    center: true,
  },
  {
    name: 'Merk Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.merk,
    sortable: true,
    center: true,
  },
  {
    name: 'Tipe Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.tipe,
    sortable: true,
    center: true,
  },
  {
    name: 'Jenis Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.jenis,
    sortable: true,
    center: true,
  },
  {
    name: 'Model Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.model,
    sortable: true,
    center: true,
  },
  {
    name: 'Warna Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.warna,
    sortable: true,
    center: true,
  },
  {
    name: 'No BPKB Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.nomor_bpkb,
    sortable: true,
    center: true,
  },
  {
    name: 'Warna TNKB Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.warna_tnkb,
    sortable: true,
    center: true,
  },
  {
    name: 'Bahan Bakar Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.bahan_bakar,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Mesin Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.nomor_mesin,
    sortable: true,
    center: true,
  },
  {
    name: 'Isi Silinder Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.isi_silinder,
    sortable: true,
    center: true,
  },
  {
    name: 'Nomor Rangka Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.nomor_rangka,
    sortable: true,
    center: true,
  },
  {
    name: 'Tahun Pembuatan Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.tahun_pembuatan,
    sortable: true,
    center: true,
  },
  {
    name: 'Tahun Registrasi Kendaraan',
    selector: row => row.stnkBawah.vehicle_details.tahun_registrasi,
    sortable: true,
    center: true,
  },
];

const IdentifierList = () => {
  const navigate = useNavigate();
  const { setHeaderMenu, layoutURL } = useContext(CustomizerContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setHeaderMenu('List STNK')

    API.akta.getListStnk()
      .then(x => {
        console.log('x', x)
        setData(x.data)
      })
      .catch(err => alert('Error retrieve STNK: ' + JSON.stringify(err)))
  }, [])

  const handleRowClick = (row) => {
    navigate(`/main/list/stnk/${row.id}/${layoutURL}`); // Navigate to details page with row ID
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  // const handleDelete = () => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to delete:\r ${selectedRows.map(
  //         (r) => r.title
  //       )}?`
  //     )
  //   ) {
  //     setToggleDelet(!toggleDelet);

  //     setData(
  //       data.filter((item) =>
  //         selectedRows.filter((elem) => elem.id === item.id).length > 0
  //           ? false
  //           : true
  //       )
  //     );
  //     setSelectedRows("");
  //   }
  // };

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row>
          <Col>
            <Card>
              <CardBody>
                {/* {selectedRows.length !== 0 && (
                  <div
                    className={`d-flex align-items-center justify-content-between bg-light-info p-2`}
                  >
                    <H4 attrH4={{ className: "text-muted m-0" }}>
                      Delet Selected Data..!
                    </H4>
                    <Btn attrBtn={{ color: "danger", onClick: () => handleDelete() }}>
                      Delete
                    </Btn>
                  </div>
                )} */}

                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleDelet}
                  onRowClicked={handleRowClick} // Add onRowClicked event
                  pointerOnHover // Highlight row on hover
                  highlightOnHover // Highlight row on hover
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IdentifierList;
