import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
} from "reactstrap";
import { H4, Btn } from "../../AbstractElements";

import InputData from './InputData'

const Button = ({ text, color="primary", className="m-r-15", style={}, onClick }) => (
  <Btn
    attrBtn={{ color, className, type: "submit", style, onClick }}
  >
    {text}
  </Btn>
)

const PreviewImage = ({ data, image, index, onClick }) => (
  <div
    className="p-3 text-center"
    style={{
      position: 'relative',
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '21rem',
      borderRadius: '15px',
    }}
  > 
    <div className="d-flex gap-2 position-absolute" style={{ bottom: '1rem' }}>
      {
        data.map((el, i) => (
          <Button
            key={i}
            className="px-2 py-1"
            color={index === i ? '#FFF' : 'transparent'}
            style={{
              border: '1px solid #808080',
              borderRadius: '8px',
              backgroundColor: '#808080'
            }}
            onClick={() => onClick(i)}
            text={<span style={{ color: index === i ? '#FFF' : '#3f3f3f' }}>STNK {el.stnkType.toUpperCase()}</span>}
          />
        ))
      }
    </div>
  </div>
)

const DynamicInputData = React.memo(({ data, onChange }) => (
  <div>
    {Object.entries(data).map(([key, value]) => {
      console.log('key', key)
      console.log('value', value)
      if (['type', 'id', 'vehicleId', 'filePath', 'fileUrl', 'taxId', 'stnkType'].includes(key)) return null;

      if (key === 'vehicle_details' && typeof value === 'object') {
        return (
          <div key={`section-${key}`}>
            <h4>Vehicle Details</h4>
            {Object.entries(value).map(([nestedKey, nestedValue]) => (
              <InputData
                key={`nested-${key}-${nestedKey}`}
                label={nestedKey.replace(/_/g, " ").toUpperCase()}
                value={nestedValue?.toUpperCase() ?? ''}
                maxLength={key === 'tahun_pembuatan' || key === 'tahun_registrasi' ? 4 : undefined}
                onChange={(event) => onChange(key, event.target.value, nestedKey)}
              />
            ))}
          </div>
        );
      } else if (key === 'tax_details' && typeof value === 'object') {
        return (
          <div key={`section-${key}`}>
            <h4>Tax Details</h4>
            {Object.entries(value).map(([nestedKey, nestedValue]) => (
              <InputData
                key={`nested-${key}-${nestedKey}`}
                label={nestedKey.replace(/_/g, " ").toUpperCase()}
                value={nestedValue?.toUpperCase() ?? ''}
                maxLength={key === 'tahun_pembuatan' || key === 'tahun_registrasi' ? 4 : undefined}
                onChange={(event) => onChange(key, event.target.value, nestedKey)}
              />
            ))}
          </div>
        );
      } else {
        console.log('masuk key', key)
        return (
          <InputData
            key={`field-${key}`}
            label={key.replace(/_/g, " ").toUpperCase()}
            value={value?.toUpperCase() ?? ''}
            maxLength={key === 'tahun_pembuatan' || key === 'tahun_registrasi' ? 4 : undefined}
            onChange={(event) => onChange(key, event.target.value)}
          />
        );
      }
    })}
  </div>
));

const movePropertiesToEnd = (obj) => {
  const reordered = {};
  for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
          reordered[key] = movePropertiesToEnd(obj[key]);
      } else {
          reordered[key] = obj[key];
      }
  }
  return reordered;
};

const EditStnk = ({ data, onRetake, onDone }) => {
  console.log('data', data)
  const fileInputRef = useRef(null);
  const [selfiePhoto, setSelfiePhoto] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    const reorderedData = data.map((item) => movePropertiesToEnd(item));
    setFormData(reorderedData);
  }, [data])

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
  };

  const changeFormData = (index) => {
    setCurrentIndex(index)
  }

  const updateFormData = (key, value, nestedKey = null) => {
    setFormData((prevData) => {
      const updatedData = [...prevData]; // Clone the array to avoid mutating state directly
  
      // Update the specific index
      const currentItem = updatedData[currentIndex];
  
      if (nestedKey) {
        updatedData[currentIndex] = {
          ...currentItem,
          [key]: {
            ...currentItem[key],
            [nestedKey]: value,
          },
        };
      } else {
        updatedData[currentIndex] = {
          ...currentItem,
          [key]: value,
        };
      }
  
      return updatedData; // Return the new state
    });
  };

  const validateFormData = (data) => {
    // Common required fields for both types
    const commonFields = [
      { field: "alamat", message: "Alamat is empty" },
      { field: "nama_pemilik", message: "Nama Pemilik is empty" },
      { field: "berlaku_sampai", message: "Berlaku Sampai is empty" },
    ];
  
    // Vehicle details validation
    const requiredVehicleFields = [
      { field: "merk", message: "Merk Kendaraan is empty" },
      { field: "tipe", message: "Tipe Kendaraan is empty" },
      { field: "jenis", message: "Jenis Kendaraan is empty" },
      { field: "warna", message: "Warna Kendaraan is empty" },
      { field: "nomor_bpkb", message: "Nomor BPKB is empty" },
      { field: "nomor_mesin", message: "Nomor Mesin is empty" },
      { field: "nomor_rangka", message: "Nomor Rangka is empty" },
      { field: "tahun_pembuatan", message: "Tahun Pembuatan is empty" },
      { field: "tahun_registrasi", message: "Tahun Registrasi is empty" },
    ];
  
    // Additional required fields for "bawah" type
    const bawahFields = [
      { field: "nik", message: "NIK is empty" },
      { field: "no_urut", message: "Nomor Urut is empty" },
      { field: "no_kohir", message: "Nomor Kohir is empty" },
      { field: "kode_lokasi", message: "Kode Lokasi is empty" },
    ];
  
    // Tax details validation for "bawah" type
    const requiredTaxFields = [
      { field: "pkb_pokok", message: "PKB Pokok is empty" },
      { field: "pkb_jumlah", message: "PKB Jumlah is empty" },
      { field: "pkb_sanksi", message: "PKB Sanksi is empty" },
      { field: "bbnkb_pokok", message: "BBNKB Pokok is empty" },
      { field: "bbnkb_jumlah", message: "BBNKB Jumlah is empty" },
      { field: "bbnkb_sanksi", message: "BBNKB Sanksi is empty" },
    ];
  
    // Validate common fields
    for (const { field, message } of commonFields) {
      if (!data?.[field]) {
        alert(message);
        return false;
      }
    }
  
    // Validate vehicle details
    const vehicleDetails = data?.vehicle_details;
    if (!vehicleDetails) {
      alert("Vehicle details are missing");
      return false;
    }
    for (const { field, message } of requiredVehicleFields) {
      if (!vehicleDetails?.[field]) {
        alert(message);
        return false;
      }
    }
  
    // Additional validation for "bawah" type
    if (data.stnkType === "bawah") {
      for (const { field, message } of bawahFields) {
        if (!data?.[field] || data[field] === "null") {
          alert(message);
          return false;
        }
      }
  
      const taxDetails = data?.tax_details;
      if (!taxDetails) {
        alert("Tax details are missing");
        return false;
      }
      for (const { field, message } of requiredTaxFields) {
        if (!taxDetails?.[field]) {
          alert(message);
          return false;
        }
      }
    }
  
    return true; // All validations passed
  };

  const validateSubmit = () => {
    if (!validateFormData(formData[currentIndex], currentIndex)) {
      // Stop further execution if validation fails
      return false;
    }

    onDone(formData);
    return true;
  }

  return (
    <Row className="justify-content-center mt-3" style={{ width: '100%' }}>
      <Col>
        <Card>
          <Form className="form theme-form" onSubmit={handleSubmit}>
            <CardHeader className="card-no-border pb-0">
              <H4>STNK {formData[currentIndex].stnkType.toUpperCase()}</H4>
            </CardHeader>

            <CardBody style={{
              maxHeight: '64vh',
              overflow: 'auto',
            }}>
              <DynamicInputData
                data={formData[currentIndex]}
                onChange={updateFormData}
              />
            </CardBody>

            <CardFooter className="text-end">
              {
                currentIndex === (data.length - 1)
                ? (
                  <Button
                    onClick={validateSubmit}
                    text="Update STNK"
                  />
                )
                : (
                  <Button
                    onClick={() => {
                      if (validateFormData(formData[currentIndex], currentIndex)) {
                        changeFormData(currentIndex + 1)
                      }
                    }}
                    text="Selanjutnya"
                  />
                )
              }      
            </CardFooter>
          </Form>
        </Card>
      </Col>

      <Col>
        <Card>
          <PreviewImage
            index={currentIndex}
            data={formData}
            image={formData[currentIndex].fileUrl}
            onClick={(index) => {
              if (validateFormData(formData[currentIndex], currentIndex)) {
                changeFormData(index)
              }
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default EditStnk;
