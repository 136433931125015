import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";

import { H4, Btn } from "../../AbstractElements";
import CustomizerContext from '../../_helper/Customizer';
import InputData from '../Scan/InputData'
import API from '../../api/services'

const Button = ({ text, color="primary", className="m-r-15", style={}, onClick }) => (
  <Btn
    attrBtn={{ color, className, type: "submit", style, onClick }}
  >
    {text}
  </Btn>
)

const PreviewImage = ({ data, image, index, onClick }) => (
  <div
    className="p-3 text-center"
    style={{
      position: 'relative',
      backgroundImage: `url('${image}')`,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '21rem',
      borderRadius: '15px',
    }}
  > 
    <div className="d-flex gap-2 position-absolute" style={{ bottom: '1rem' }}>
      {
        data.map((el, i) => (
          <Button
            key={i}
            className="px-2 py-1"
            color={index === i ? '#FFF' : 'transparent'}
            style={{
              border: '1px solid #808080',
              borderRadius: '8px',
              backgroundColor: '#808080'
            }}
            onClick={() => onClick(i)}
            text={<span style={{ color: index === i ? '#FFF' : '#3f3f3f' }}>STNK {el.stnkType.toUpperCase()}</span>}
          />
        ))
      }
    </div>
  </div>
)

const DynamicInputData = React.memo(({ data, onChange }) => (
  <div>
    {Object.entries(data).map(([key, value]) => {
      console.log('key', key)
      console.log('value', value)
      if (['type', 'id', 'vehicleId', 'filePath', 'fileUrl', 'taxId', 'stnkType'].includes(key)) return null;

      if (key === 'vehicle_details' && typeof value === 'object') {
        return (
          <div key={`section-${key}`}>
            <h4>Vehicle Details</h4>
            {Object.entries(value).map(([nestedKey, nestedValue]) => (
              <InputData
                key={`nested-${key}-${nestedKey}`}
                label={nestedKey.replace(/_/g, " ").toUpperCase()}
                value={nestedValue?.toUpperCase() ?? ''}
                disabled
              />
            ))}
          </div>
        );
      } else if (key === 'tax_details' && typeof value === 'object') {
        return (
          <div key={`section-${key}`}>
            <h4>Tax Details</h4>
            {Object.entries(value).map(([nestedKey, nestedValue]) => (
              <InputData
                key={`nested-${key}-${nestedKey}`}
                label={nestedKey.replace(/_/g, " ").toUpperCase()}
                value={nestedValue?.toUpperCase() ?? ''}
                disabled
              />
            ))}
          </div>
        );
      } else {
        console.log('masuk key', key)
        return (
          <InputData
            key={`field-${key}`}
            label={key.replace(/_/g, " ").toUpperCase()}
            value={value?.toUpperCase() ?? ''}
            disabled
          />
        );
      }
    })}
  </div>
));

const movePropertiesToEnd = (obj) => {
  const reordered = {};
  for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
          reordered[key] = movePropertiesToEnd(obj[key]);
      } else {
          reordered[key] = obj[key];
      }
  }
  return reordered;
};

const StnkDetail = () => {
  const { setHeaderMenu } = useContext(CustomizerContext);
  const { id } = useParams(); // Extract the :id from the URL

  const [formData, setFormData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copiedText, setCopiedText] = useState(false);

  useEffect(() => {
    setHeaderMenu('Detail STNK')

    API.akta.getDetailStnk(id)
      .then(x => {
        console.log(x)

        const reorderedData = x.map((item) => movePropertiesToEnd(item));
        setFormData(reorderedData);

        const stnkName = `${x[0].vehicle_details.merk} ${x[0].vehicle_details.tipe}`;
        const historyData = {
          name: `STNK ${stnkName}`,
          url: `list/stnk/${id}`,
          type: 'stnk',
        }
        API.history.insertHistory(historyData).then(x => console.log('success saved history'))
      })
      .catch(err => alert('Error retrieve detail KTP: ' + JSON.stringify(err)))

  }, [])


  const changeFormData = (index) => {
    setCurrentIndex(index)
  }

  return (
    <Fragment>
      <Container fluid={true} className="pt-3">
        {Object.keys(formData).length > 0 && (
          <Row>
            <Col>
              <Card>
                <CardHeader className="card-no-border pb-0">
                  <H4>STNK {formData[currentIndex].stnkType.toUpperCase()}</H4>
                </CardHeader>
    
                <CardBody style={{
                  maxHeight: '75vh',
                  overflow: 'auto',
                }}>
                  <DynamicInputData
                    data={formData[currentIndex]}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <PreviewImage
                  index={currentIndex}
                  data={formData}
                  image={formData[currentIndex].fileUrl}
                  onClick={(index) => {
                    changeFormData(index)
                  }}
                />
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default StnkDetail;

const styles = {
  separator: {
    borderBottom: '1px solid #e9e9e9',
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
}
